import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

export const FbCallbackHandler = () => {
  const [authResult, setAuthResult] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(async () => {
    const handleAuth = async () => {
      const queryParams = new URLSearchParams(location.search);
      const accessToken = queryParams.get('access_token');
      console.log("access_token",accessToken);
      if(!accessToken){
        window.location.href = "/admin/login"
      }else {

      try {
        const res = await fetch(`https://dafukcrew.com/auth/validate?access_token=${accessToken}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });
        console.log("response",res);
        if (!res.ok) {
          window.location.href = "/admin/login"
        } else {
          const result = await res.json();
          setAuthResult(result);
          console.log("AuthResult:", result)
          Cookies.set('fbauthToken', accessToken, { expires: 15, path: '/' });
          Cookies.set('userId', result.userId, { expires: 15, path: '/' });
          window.location.href = "/"
        }
      } catch (error) {
        setError(error);
        window.location.href = "/admin/login"
      }
    }
    };

    await handleAuth();
  }, [location, navigate]);

  if (error) {
    return <div>Error occurred: {error.message}</div>;
  }

  if (!authResult) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Authentication Successful</h1>
      <p>Welcome, User {authResult.userId}!</p>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import "normalize.css";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import SideNav from "./components/SideNav/SideNav";
import Dashboard from "./components/content/Dashboard/Dashboard";
import EventForm from "./components/content/Dashboard/EventForm";
import Participants from "./components/content/Participants/Participants";
import ParticipantDetails from "./components/content/Participants/participantDetails/ParticipantDetails";
import ParticipantPreference from "./components/content/Participants/participantPreference/ParticipantPreference";
import Posts from "./components/content/Participants/posts/Posts";
import EditOnboardingQuestion from "./components/content/Participants/participantPreference/EditOnboardingQuestion";
import Sponsors from "./components/content/sponsors/Sponsors";
import SponsorDetails from "./components/content/sponsors/sponsorDetails/SponsorDetails";
import Events from "./components/content/events/Events";
import Donation from "./components/content/donations/Donation";
import DonationSettings from "./components/content/donations/donationSettings/DonationSettings";
import VotingSetting from "./components/content/voting/votingSetting/VotingSetting";
import RoleManagement from "./components/content/rolemanagement/RoleManagement";
import EditRole from "./components/content/rolemanagement/editRole/EditRole";
import CollaborationRequest from "./components/content/sponsors/collaborationRequest/CollaborationRequest";
import EditEventForm from "./components/content/Dashboard/editevent/EditEventForm";
import Category from "./components/content/category/Category";
import { Login } from "./components/loginPage/Login";
import Cookies from 'js-cookie';
import { FbCallbackHandler } from "./components/loginPage/loginHandelSystem/fbCallbackHandler";
import { Venue } from "./components/content/venue/Venue";
import EventHashtag from "./components/content/eventHashtag/EventHashtag";
import { Instagram } from "./components/content/Participants/instagramUI/Instagram";
import { SponsorInsta } from "./components/content/sponsors/sponsorListTable/SponsorInsta";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {

    const checkAuth = async () => {
      const authToken = Cookies.get("authToken");
        const fbAuthToken = Cookies.get("fbauthToken");
        const loginTime = Cookies.get("loginTime");
        const userId = Cookies.get("userId");
        if (authToken && fbAuthToken && loginTime && ((Date.now() - loginTime) < 24 * 60 * 60 * 1000)) {
          console.log("logged In Successfully")
          setIsLoggedIn(true);
        }else{
          setIsLoggedIn(false);
        }
    };
    checkAuth();
  }, [location, navigate]);

  return (
    <div className="App">
      <SideNav />
      <div className="mainContent">
        <Navbar />
        <div className="content">
          <Routes>
          {!isLoggedIn ?(  <Route path="/admin/login" element={<Login />} /> ) : (  <Route path="/admin/login" element={<Dashboard />} /> )}
            <Route path="/auth/facebook/callback" element={<FbCallbackHandler />} />

            {isLoggedIn ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/participants/:id/:instagramBusinessAccountId" element={<Instagram/>}/>
                <Route path="/participants" element={<Participants />} />
                <Route path="/category" element={<Category />} />
                <Route path="/venue" element={<Venue />} />
                <Route path="/eventhashtag" element={<EventHashtag />} />
                <Route path="/sponsors" element={<Sponsors />} />
                <Route path="/collaboration-request" element={<CollaborationRequest />} />
                <Route path="/events" element={<Events />} />
                <Route path="/donations" element={<Donation />} />
                <Route path="/voting-settings" element={<VotingSetting />} />
                <Route path="/role-management" element={<RoleManagement />} />
                <Route path="/event-form" element={<EventForm />} />
                <Route path="/edit-event-form" element={<EditEventForm />} />
                <Route path="/edit-role" element={<EditRole />} />
                <Route path="/donation-settings-form" element={<DonationSettings />} />
                <Route path="/participants/:participantId" element={<ParticipantDetails />} />
                <Route path="/sponsor/:participantId" element={<SponsorDetails />} />
                <Route path="/sponsors/:id/:instagramBusinessAccountId" element={<SponsorInsta/>}/>
                <Route path="/participant/:participantId/preferences" element={<ParticipantPreference />} />
                <Route path="/participant/:participantId/post" element={<Posts />} />
                <Route path="/edit-participant-question" element={<EditOnboardingQuestion />} />
              </>
            ) : (
              <Route path="*" element={<Navigate to="/admin/login" />} />
            )}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
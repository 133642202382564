import React, { useState } from "react";
import arrowUp from "./uparrow.png";

const TotalStat = () => {
  const [data, setData] = useState([
    { icon: arrowUp, label: "Total Participants", number: "1", percentage: 60 },
    {
      icon: arrowUp,
      label: "Monthly Participant",
      number: "85",
      percentage: 60,
    },
    { icon: arrowUp, label: "Achievements", number: "120", percentage: 90 },
  ]);

  return (
    <div className="flex flex-col mt-20">
      <div className="w-full flex justify-between items-center">
       <strong className="text-2xl text-[#4880FF]">Dashboard</strong> 
       {/* <button className="bg- px-4 py-3 text-[#fff] text-lg rounded-md shadow-md hover:transform hover:translate-x-1 duration-500">Homepage Management</button> */}
      </div>
      <div className="w-full flex flex-wrap items-center justify-between pt-8">
        {data.map((item, index) => (
          <div
            key={index}
            className="w-1/4 flex items-center justify-between text-left  bg-white rounded-lg shadow-md p-4"
          >
            <div className="relative w-[60px] h-[60px] border-4 border-black rounded-full flex justify-center items-center">
              <img src={item.icon} alt="Icon" className="w-5 h-5" />
            </div>
            <div className="flex flex-col">
              <div className="font-normal text-gray-500 text-sm leading-[24.5px] font-inter">
                {item.label}
              </div>
              <div className="text-gray-900 text-lg font-semibold leading-[33.25px] font-worksans">
                {item.number}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TotalStat;

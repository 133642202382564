import React from "react";
import styles from "./TotalStatEvent.module.css";
import arrowUp from "../uparrow.png";

const data = [
  { icon: arrowUp, label: "Total Participant", number: "85", percentage: 60 },
  { icon: arrowUp, label: "This month", number: "85", percentage: 60 },
  { icon: arrowUp, label: "Active", number: "120", percentage: 90 },
  { icon: arrowUp, label: "Total Participant", number: "85", percentage: 60 },
  { icon: arrowUp, label: "This month", number: "85", percentage: 60 },
  { icon: arrowUp, label: "Active", number: "120", percentage: 90 },
];

const TotalStatEvent = () => {
  return (
    <div className={styles.statContainer}>
      {data.map((item, index) => (
        <div key={index} className={styles.totalStat}>
          <div className={styles.iconContainer}>
            <img src={item.icon} alt="Icon" className={styles.icon} />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.label}>{item.label}</div>
            <div className={styles.number}>{item.number}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TotalStatEvent;

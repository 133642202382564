import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CategoryTable.module.css";
import editIcon from "../../../assets/edit.png";
import delIcon from "../../../assets/del.png";
import axios from "axios";
import Cookies from 'js-cookie';
import { DataContext } from "../../../context/DataContext";

const CategoryTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 4;
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const navigate = useNavigate();

  const { categories, genderCategories, ageCategories, followerCountCategories, loading } = useContext(DataContext);

  const [editingId, setEditingId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    name: "",
    description: "",
    startAge: "",
    endAge: "",
    gender: "",
    startCount: "",
    endCount: "",
  });

  if (loading) <></>;

  // List of category options
  const categoryTypes = [
    { value: "generic", label: "Generic" },
    { value: "age", label: "Age" },
    { value: "gender", label: "Gender" },
    { value: "followercount", label: "Followers Count" },
  ];

  const [selectedCategory, setSelectedCategory] = useState("generic");

  // Filter data based on selected category
  let categoryData = [];
  let tableHeaders = [];

  switch (selectedCategory) {
    case "age":
      categoryData = ageCategories;
      tableHeaders = ["Category Name", "Start Age", "End Age", "Action"];
      break;
    case "gender":
      categoryData = genderCategories;
      tableHeaders = ["Gender", "Action"];
      break;
    case "followercount":
      categoryData = followerCountCategories;
      tableHeaders = ["Start Count", "End Count", "Action"];
      break;
    default:
      categoryData = categories;
      tableHeaders = ["Category Name", "Description", "Action"];
      break;
  }

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = categoryData.slice(indexOfFirstEvent, indexOfLastEvent + eventsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle change event for category selection
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setCurrentPage(1); // Reset to first page when changing category
  };

  const deleteCategory = async (categoryId) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      const response = await axios.delete(`${apiDomain}/api/v2/deletecategory?id=${categoryId}&access_token=${accessToken}`);
      console.log('Response:', response.data);
      alert('Category deleted successfully');
      navigate('/category');
    } catch (error) {
      if (error.response) {
        console.error('Error Response:', error.response.data);
        alert(`Error: ${error.response.data}`);
      } else {
        console.error('Error Message:', error.message);
        alert(`Error: ${error.message}`);
      }
    }
  };

  const handleEditClick = (category) => {
    setEditingId(category.id);
    setEditFormData({
      name: category.name || "",
      description: category.description || "",
      startAge: category.startAge || "",
      endAge: category.endAge || "",
      gender: category.gender || "",
      startCount: category.startCount || "",
      endCount: category.endCount || "",
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  // Updated handleSaveClick to use categoryId
  const handleSaveClick = async (categoryId) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      let url = `${apiDomain}/api/v2/updatecategory?id=${categoryId}&access_token=${accessToken}&categoryType=${selectedCategory}`;
      let payload = {};
  
      switch (selectedCategory) {
        case "generic":
          payload = {
            name: editFormData.name, // Include name field for generic
            description: editFormData.description, // Updating description for generic category
          };
          break;
        case "age":
          payload = {
            name: editFormData.name, // Include name field for age category
            startAge: parseInt(editFormData.startAge, 10),  // Convert to integer
            endAge: parseInt(editFormData.endAge, 10),      // Convert to integer
          };
          break;
        case "gender":
          payload = {
            gender: editFormData.gender,  // Updating gender for gender category
          };
          break;
        case "followercount":
          payload = {
            startCount: parseInt(editFormData.startCount, 10),  // Convert to integer
            endCount: parseInt(editFormData.endCount, 10),      // Convert to integer
          };
          break;
        default:
          return; // No category type selected
      }
  
      await axios.put(url, payload);
      alert("Category updated successfully");
      setEditingId(null);
      window.location.reload(); // Exit editing mode after save
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to update category");
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
  };

  const renderTableRows = (category) => {
    return (
      <>
        {/* Show the name field for generic and age categories */}
        {(selectedCategory === "generic" || selectedCategory === "age") && (
          <td>
            {editingId === category.id ? (
              <input
                type="text"
                name="name"
                value={editFormData.name}
                onChange={handleEditChange}
              />
            ) : (
              category.name
            )}
          </td>
        )}

        {/* Show the description field only for generic category */}
        {selectedCategory === "generic" && (
          <td>
            {editingId === category.id ? (
              <input
                type="text"
                name="description"
                value={editFormData.description}
                onChange={handleEditChange}
              />
            ) : (
              category.description
            )}
          </td>
        )}

        {/* Handle age, gender, and follower count fields */}
        {selectedCategory === "age" && (
          <>
            <td>
              {editingId === category.id ? (
                <input
                  type="number"
                  name="startAge"
                  value={editFormData.startAge}
                  onChange={handleEditChange}
                />
              ) : (
                category.startAge
              )}
            </td>
            <td>
              {editingId === category.id ? (
                <input
                  type="number"
                  name="endAge"
                  value={editFormData.endAge}
                  onChange={handleEditChange}
                />
              ) : (
                category.endAge
              )}
            </td>
          </>
        )}

        {selectedCategory === "gender" && (
          <td>
            {editingId === category.id ? (
              <input
                type="text"
                name="gender"
                value={editFormData.gender}
                onChange={handleEditChange}
              />
            ) : (
              category.gender
            )}
          </td>
        )}

        {selectedCategory === "followercount" && (
          <>
            <td>
              {editingId === category.id ? (
                <input
                  type="number"
                  name="startCount"
                  value={editFormData.startCount}
                  onChange={handleEditChange}
                />
              ) : (
                category.startCount
              )}
            </td>
            <td>
              {editingId === category.id ? (
                <input
                  type="number"
                  name="endCount"
                  value={editFormData.endCount}
                  onChange={handleEditChange}
                />
              ) : (
                category.endCount
              )}
            </td>
          </>
        )}

        {/* Action Buttons */}
        <td>
          {editingId === category.id ? (
            <>
              <button
                className={styles.saveButton}
                onClick={() => handleSaveClick(category.id)}
              >
                <img src="https://img.icons8.com/?size=100&id=13279&format=png&color=000000" alt="save" className="" />
              </button>
              <button
                className={styles.cancelButton}
                onClick={handleCancelClick}
              >
                <img src="https://img.icons8.com/?size=100&id=7703&format=png&color=000000" alt="cancel" className="" />
              </button>
            </>
          ) : (
            <>
              <button
                className={styles.editButton}
                onClick={() => handleEditClick(category)}
              >
                <img src={editIcon} alt="edit" />
              </button>
              <button
                className={styles.deleteButton}
                onClick={() => deleteCategory(category.id)}
              >
                <img src={delIcon} alt="delete" />
              </button>
            </>
          )}
        </td>
      </>
    );
  };

  return (
    <>
      {/* Category Selector */}
      <div className="w-[48%] mb-5">
        <label
          htmlFor="categories"
          className="block text-2xl font-semibold text-black mb-2"
        >
          Category Type
        </label>
        <select
          id="categories"
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          {categoryTypes.map((category) => (
            <option key={category.value} value={category.value}>
              {category.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <div className={styles.eventsTable}>
          <table className={styles.tableEvent}>
            <thead>
              <tr className={styles.eventTr}>
                {tableHeaders.map((header) => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentEvents && currentEvents.map((category) => (
                <tr key={category.id}>
                  {renderTableRows(category)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className={styles.pagination}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.paginationButton}
          >
            Prev
          </button>
          <p className={styles.pageText}>
            Page {currentPage}/{Math.ceil(categoryData.length / eventsPerPage)}
          </p>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastEvent >= categoryData.length}
            className={styles.paginationButton}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default CategoryTable;

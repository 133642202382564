import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./VotingSetting.module.css";
import { DataContext } from '../../../context/DataContext';
import axios from "axios";
import Cookies from 'js-cookie';

const VotingSetting = () => {
  const navigate = useNavigate();
  
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const accessToken = Cookies.get("fbauthToken");
  
  const { audienceVotingWeights,sponsorVotingWeights, loading } = useContext(DataContext);
  if(loading)
    <></>
  console.log('audiencevotingWeights', audienceVotingWeights)
  console.log('sponsorvotingWeights', sponsorVotingWeights)
  
  const [formData, setFormData] = useState({
    audience: {
      likes: 0,
      comments: 0,
      shares: 0,
    },
    sponsor: {
      likes: 0,
      comments: 0,
      shares: 0,
    },
  });

  // Set initial form data when the context data is loaded
  useEffect(() => {
    if (!loading) {
      setFormData({
        audience: {
          likes: audienceVotingWeights?.audienceVotingWeight?.likes || "",
          comments: audienceVotingWeights?.audienceVotingWeight?.comments || "",
          shares: audienceVotingWeights?.audienceVotingWeight?.shares || "",
        },
        sponsor: {
          likes: sponsorVotingWeights?.sponsorVotingWeight?.likes || "",
          comments: sponsorVotingWeights?.sponsorVotingWeight?.comments || "",
          shares: sponsorVotingWeights?.sponsorVotingWeight?.shares || "",
        },
      });
    }
  }, [loading, audienceVotingWeights, sponsorVotingWeights]);

  const handleInputChange = (section, e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [name]: value,
      },
    }));
  };

  const handleSaveClick = async (type) => {
    try {
      const response = await axios.put(`${apiDomain}/api/v2/updatevotingweight?type=${type}`, formData[type], {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          access_token: accessToken
        }
      });
  
      // Handle the response
      if (response.data.response.status_code === "200") {
        alert('Update successful:', response.data);
      } else {
        alert('Update failed:', response.data.response.message);
        console.log(response);
      }
    } catch (error) {
      alert('Error updating voting data:', error);
      console.log(error);
    }
    // Perform the save operation, then update context or state as needed
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className={styles.container}>
      <form className={styles.eventForm}>
        <div className={styles.header}>
          <h2 className={styles.title}>Audience Voting Weight</h2>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="like">1 Like: </label>
            <div className={styles.inputWrapper}>
              <input
                type="number"
                id="like"
                name="likes"
                className={styles.inputField}
                value={formData.audience.likes}
                onChange={(e) => handleInputChange("audience", e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="comment">1 Comment:</label>
            <div className={styles.inputWrapper}>
              <input
                type="number"
                id="comment"
                name="comments"
                className={styles.inputField}
                value={formData.audience.comments}
                onChange={(e) => handleInputChange("audience", e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="share">1 Views:</label>
            <div className={styles.inputWrapper}>
              <input
                type="number"
                id="share"
                name="shares"
                className={styles.inputField}
                value={formData.audience.shares}
                onChange={(e) => handleInputChange("audience", e)}
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          className="w-full flex items-end justify-end mt-4"
          onClick={() => handleSaveClick("audience")}
        >
          <span className="text-[1.5vw] font-semibold px-3 py-0 hover:translate-x-1 transform duration-500 border border-black shadow-lg rounded-md">
            Save
          </span>
        </button>

        <div className={styles.header}>
          <h2 className={styles.title}>Sponsor Voting Weight</h2>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="likesponsor">1 Like: </label>
            <div className={styles.inputWrapper}>
              <input
                type="number"
                id="likesponsor"
                name="likes"
                className={styles.inputField}
                value={formData.sponsor.likes}
                onChange={(e) => handleInputChange("sponsor", e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="commentsponsor">1 Comment:</label>
            <div className={styles.inputWrapper}>
              <input
                type="number"
                id="commentsponsor"
                name="comments"
                className={styles.inputField}
                value={formData.sponsor.comments}
                onChange={(e) => handleInputChange("sponsor", e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label htmlFor="sharesponsor">1 Views:</label>
            <div className={styles.inputWrapper}>
              <input
                type="number"
                id="sharesponsor"
                name="shares"
                className={styles.inputField}
                value={formData.sponsor.shares}
                onChange={(e) => handleInputChange("sponsor", e)}
              />
            </div>
            <button
              type="button"
              className="w-full flex items-end justify-end mt-4"
              onClick={() => handleSaveClick("sponsor")}
            >
              <span className="text-[1.5vw] font-semibold px-3 py-0 hover:translate-x-1 transform duration-500 border border-black shadow-lg rounded-md">
                Save
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VotingSetting;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles1 from "../category/AddCategory/AddCategory.module.css";
import styles from "../category/CategoryTable/CategoryTable.module.css";
import backIcon from "../category/AddCategory/backIcon.png";
import axios from "axios";
import Cookies from "js-cookie";
import { DataContext } from "../../context/DataContext";

const EventHashtag = () => {
  const { eventData, loading } = useContext(DataContext);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [formData, setFormData] = useState({
    hashtagName: "",
    eventId: "",
  });
  const [hashtags, setHashtags] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchHashtags = async () => {
      if (!eventData || loading) return;

      try {
        const myhashtags = [];
        for (const event of eventData) {
          const accessToken = Cookies.get("fbauthToken");
          const response = await axios.get(
            `${apiDomain}/api/v2/gethashtagsbyevent?event_id=${event.eventID}&access_token=${accessToken}`
          );
          myhashtags.push({ id: event.eventID, tags: response.data });
        }
        setHashtags(myhashtags);
        console.log("hashTags", myhashtags)
      } catch (error) {
        console.error("Error fetching hashtags:", error);
      }
    };

    fetchHashtags();
  }, [loading, eventData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const accessToken = Cookies.get("fbauthToken");

    try {
      // Sending the POST request with correct JSON payload
      await axios.post(
        `${apiDomain}/api/v2/createhashtag?access_token=${accessToken}`,
        {
          hashtag_name: formData.hashtagName, // Using the correct key as expected by the Go handler
          event_id: parseInt(formData.eventId), // Converting eventId to an integer, as expected by the Go handler
        }
      );
      alert("Hashtag added successfully");

      // Refresh the list of hashtags
      try {
        const myhashtags = [];
        for (const event of eventData) {
          const response = await axios.get(
            `${apiDomain}/api/v2/gethashtagsbyevent?event_id=${event.eventID}&access_token=${accessToken}`
          );
          myhashtags.push({ id: event.eventID, tags: response.data });
        }
        setHashtags(myhashtags);
      } catch (error) {
        console.error("Error fetching hashtags:", error);
      }

      // Clear the form data
      setFormData({ hashtagName: "", eventId: "" });
    } catch (error) {
      if (error.response) {
        console.log("Error Response:", error.response.data);
        alert(`Error: ${error.response.data}`);
      } else {
        console.log("Error Message:", error.message);
        alert(`Error: ${error.message}`);
      }
    }
  };

  const handleDelete = async (hashtagName, eventId) => {
    const accessToken = Cookies.get("fbauthToken");
  
    try {
      // Send the DELETE request with query parameters
      await axios.delete(
        `${apiDomain}/api/v2/deletehashtag?access_token=${accessToken}&hashtag_name=${encodeURIComponent(hashtagName)}&event_id=${encodeURIComponent(eventId)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      alert("Hashtag deleted successfully");
  
      // Refresh the list of hashtags
      try {
        const myhashtags = [];
        for (const event of eventData) {
          const response = await axios.get(
            `${apiDomain}/api/v2/gethashtagsbyevent?event_id=${event.eventID}&access_token=${accessToken}`
          );
          myhashtags.push({ id: event.eventID, tags: response.data });
        }
        setHashtags(myhashtags);
      } catch (error) {
        console.error("Error fetching hashtags:", error);
      }
    } catch (error) {
      if (error.response) {
        console.log("Error Response:", error.response.data);
        alert(`Error: ${error.response.data}`);
      } else {
        console.log("Error Message:", error.message);
        alert(`Error: ${error.message}`);
      }
    }
  };
  
  

  return (
    <div className={styles1.container}>
      <form className={styles1.eventForm} onSubmit={handleSubmit}>
        <div className={styles1.header}>
          <button
            type="button"
            className={styles1.backButton}
            onClick={() => navigate("/")}
          >
            <img src={backIcon} alt="backIcon" />
          </button>
          <h2 className={styles1.title}>Manage Hashtags</h2>
        </div>
        <div className={styles1.row}>
          <div className={styles1.column}>
            <label htmlFor="hashtagName">Hashtag Name</label>
            <input
              type="text"
              id="hashtagName"
              name="hashtagName"
              value={formData.hashtagName}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles1.column}>
            <label htmlFor="eventId">Event</label>
            <select
              id="eventId"
              name="eventId"
              value={formData.eventId}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select an Event
              </option>
              {eventData && eventData.map((event) => (
                <option key={event.eventID} value={event.eventID}>
                  {event.eventName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button type="submit" className={styles1.viewButton}>
          Add Hashtag
        </button>
      </form>
      <h3>Existing Hashtags</h3>
      <div className={styles.eventsTable}>
        <table className={styles.tableEvent}>
          <thead>
            <tr className={styles.eventTr}>
              <th>Event Name</th>
              <th>Hashtag</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {hashtags && hashtags.length > 0 && hashtags.map((hashtag) => (
              <>
                {hashtag.tags && hashtag.tags.map((tag) => (
                  <tr>
                    <td>
                      {eventData && eventData.find((x) => x.eventID == hashtag.id) ? eventData.find((x) => x.eventID == hashtag.id).eventName :
                      "No Name" }
                    </td>
                    <td>{tag}</td>
                    <td>
                      {
                        <button
                          onClick={() => handleDelete(tag, hashtag.id)}
                          className={styles.deleteButton}
                        >
                          Delete
                        </button>
                      }
                    </td>
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventHashtag;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../category/AddCategory/AddCategory.module.css"
import backIcon from "../category/AddCategory/backIcon.png";
import axios from "axios";
import Cookies from "js-cookie";

const AddVenue = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    address: "",
    city: "",
    state: "",
    pin_code: "",
    country: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const accessToken = Cookies.get("fbauthToken");
    
    // Axios POST request
    axios
      .post(`https://dafukcrew.com/api/v2/createvenues?access_token=${accessToken}`, formData)
      .then((response) => {
        console.log("Form data saved:", response.data);
        alert("Venue added successfully");
        navigate("/"); // Navigate to the home page after successful submission
      })
      .catch((error) => {
        if (error.response) {
          console.log("Error Response:", error.response.data);
          alert(`Error: ${error.response.data}`);
        } else {
          console.log("Error Message:", error.message);
          alert(`Error: ${error.message}`);
        }
      });
  };

  return (
    <div className="">
      <form className="w-3/4 bg-white border-2 shadow-lg px-8" onSubmit={handleSubmit}>
        <div className={styles.header}>
          <button
            type="button"
            className={styles.backButton}
            onClick={() => navigate("/")}
          >
            <img src={backIcon} alt="backIcon" />
          </button>
          <h2 className={styles.title}>Add Venue</h2>
        </div>
        <div className="w-full flex flex-wrap items-start gap-12">
          <div className="border-2">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-40 border-2 p-4 ">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-40 border-2 p-4 ">
            <label htmlFor="state">State</label>
            <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-40 border-2 p-4 ">
            <label htmlFor="pin_code">Pin Code</label>
            <input
              type="text"
              id="pin_code"
              name="pin_code"
              value={formData.pin_code}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-40 border-2 p-4 ">
            <label htmlFor="country">Country</label>
            <input
            className="bg--900"
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <button type="submit" className={styles.submitButton}>
          Add Venue
        </button>
      </form>
    </div>
  );
};

export default AddVenue;

import React, { useContext } from "react";
import TotalStatEvent from "./totalStatEvent/TotalStatEvent";
import EventTable from "./eventTable/EventTable";
import { DataContext } from "../../context/DataContext";

const Events = () => {
  const {eventData, loading} = useContext(DataContext);
  if(loading)
    return <></>

  return (
    <div>
      <TotalStatEvent />
      <EventTable eventData={eventData} Dashboard={false}/>
    </div>
  );
};

export default Events;

import React from "react";
import AddCategory from "./AddCategory/AddCategory";
import CategoryTable from "./CategoryTable/CategoryTable";

const Category = () => {
  return (
    <div>
      <AddCategory />
      <CategoryTable />
    </div>
  );
};

export default Category;

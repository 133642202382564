import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../category/CategoryTable/CategoryTable.module.css";
import editIcon from "../../assets/edit.png";
import delIcon from "../../assets/del.png";
import axios from "axios";
import Cookies from 'js-cookie';
import { DataContext } from "../../context/DataContext";

const VenueTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const venuesPerPage = 4;
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const navigate = useNavigate();

  const { venues, loading } = useContext(DataContext);
  const [editingId, setEditingId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    address: "",
    city: "",
    state: "",
    pin_code: "",
    country: "",
  });

  if (loading) return <></>;

  const indexOfLastVenue = currentPage * venuesPerPage;
  const indexOfFirstVenue = indexOfLastVenue - venuesPerPage;
  const currentVenues = venues.slice(indexOfFirstVenue, indexOfLastVenue);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deleteVenue = async (id) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      const response = await axios.delete(`${apiDomain}/api/v2/deletevenues?id=${id}&access_token=${accessToken}`);
      console.log('Response:', response.data);
      alert('Venue deleted successfully');
      navigate('/venue');
      // Refresh the page or update state after deletion
    } catch (error) {
      if (error.response) {
        console.error('Error Response:', error.response.data);
        alert(`Error: ${error.response.data}`);
      } else {
        console.error('Error Message:', error.message);
        alert(`Error: ${error.message}`);
      }
    }
  };

  const handleEditClick = (venue) => {
    setEditingId(venue.id);
    setEditFormData({
      address: venue.address,
      city: venue.city,
      state: venue.state,
      pin_code: venue.pin_code,
      country: venue.country,
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleSaveClick = async (id) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      await axios.put(`${apiDomain}/api/v2/updatevenues?id=${id}&access_token=${accessToken}`, editFormData);
      alert('Venue updated successfully');
      setEditingId(null);
      window.location.reload(); // Exit editing mode after save
      // Refresh the page or update state after saving
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to update venue');
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
  };

  return (
    <>
      <div>
        <div className={styles.eventsTable}>
          <table className={styles.tableEvent}>
            <thead>
              <tr className={styles.eventTr}>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Pin Code</th>
                <th>Country</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentVenues.map((venue) => (
                <tr key={venue.id}>
                  <td>
                    {editingId === venue.id ? (
                      <input
                        type="text"
                        name="address"
                        value={editFormData.address}
                        onChange={handleEditChange}
                      />
                    ) : (
                      venue.address
                    )}
                  </td>
                  <td>
                    {editingId === venue.id ? (
                      <input
                        type="text"
                        name="city"
                        value={editFormData.city}
                        onChange={handleEditChange}
                      />
                    ) : (
                      venue.city
                    )}
                  </td>
                  <td>
                    {editingId === venue.id ? (
                      <input
                        type="text"
                        name="state"
                        value={editFormData.state}
                        onChange={handleEditChange}
                      />
                    ) : (
                      venue.state
                    )}
                  </td>
                  <td>
                    {editingId === venue.id ? (
                      <input
                        type="text"
                        name="pin_code"
                        value={editFormData.pin_code}
                        onChange={handleEditChange}
                      />
                    ) : (
                      venue.pin_code
                    )}
                  </td>
                  <td>
                    {editingId === venue.id ? (
                      <input
                        type="text"
                        name="country"
                        value={editFormData.country}
                        onChange={handleEditChange}
                      />
                    ) : (
                      venue.country
                    )}
                  </td>
                  <td>
                    {editingId === venue.id ? (
                      <>
                        <button
                          className={styles.saveButton}
                          onClick={() => handleSaveClick(venue.id)}
                        >
                          <img src="https://img.icons8.com/?size=100&id=13279&format=png&color=000000" alt="save" />
                        </button>
                        <button
                          className={styles.cancelButton}
                          onClick={handleCancelClick}
                        >
                          <img src="https://img.icons8.com/?size=100&id=7703&format=png&color=000000" alt="cancel" />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={styles.editButton}
                          onClick={() => handleEditClick(venue)}
                        >
                          <img src={editIcon} alt="edit" />
                        </button>
                        <button
                          className={styles.deleteButton}
                          onClick={() => deleteVenue(venue.id)}
                        >
                          <img src={delIcon} alt="delete" />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className={styles.pagination}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.paginationButton}
          >
            Prev
          </button>
          <p className={styles.pageText}>
            Page {currentPage}/{Math.ceil(venues.length / venuesPerPage)}
          </p>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastVenue >= venues.length}
            className={styles.paginationButton}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default VenueTable;


import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export const SponsorshipDetail = ({ details, eventData, categories, ageCategories, genderCategories, followerCountCategories,token }) => {
  const [error, setError] = useState(null)
  const [insights, setInsights] = useState(null)
  const [loading, setLoading] = useState(false)
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const getResponse = async (url) => {
    try {
      const response = await axios.get(url, {
        params: {
          access_token: token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching media details:', error);
      throw error;
    }
  };
  useEffect(() => {
    if(!details){
        return
    }
    const fetchInsights = async (userId) => {
        setLoading(true);
        try {
          const initialUrl = `${apiDomain}/api/v2/user/monthly?id=${userId}&access_token=${token}&period=day`;
          const data = await getResponse(initialUrl);
          console.log("filtereddata",details)
          console.log("insights", data)
          setInsights(data);
          
        // Update prevUrl from response
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };
      fetchInsights(details.ig_user.id);
  },[details])

  return (
    <>
      {details &&
        details.eventCategories[0] &&
        details.eventCategories[0].map((x) => (
          <div className="flex flex-col items-start space-y-6 py-8 border-2 rounded-md p-6 mt-8">
            <div className="w-full border-b flex items-center justify-between pb-5">
              <strong>
                Event Name:{" "}
                <span className="text-gray-600 font-medium text-base ml-2 ">
                  {eventData &&
                    eventData.find((y) => y.eventID == x.eventId).eventName}
                </span>
              </strong>
              <strong>
                Start Date:{" "}
                <span className="text-gray-600 font-medium text-base ml-2 ">
                {
                  eventData &&
                    eventData.find((y) => y.eventID == x.eventId) &&
                    new Date(
                      eventData.find((y) => y.eventID == x.eventId).startDate
                    ).toLocaleDateString("en-GB") // 'en-GB' format is dd/mm/yyyy
                }{" "}
                </span>
              </strong>
              <strong>
                Ending Date:{" "}
                {eventData &&
                  eventData.find((y) => y.eventID == x.eventId) &&
                  new Date(
                    eventData.find((y) => y.eventID == x.eventId).endDate
                  ).toLocaleDateString("en-GB")}
              </strong>
            </div>
            <div className="w-full border-b flex items-center justify-between pb-5">
              <strong>Sponsor Name: {details.ig_user.name} </strong>
              <strong>
                Follower Count:{" "}
                {(details.ig_user.followers_count)}
              </strong>
              <strong>Events Sponsored: {details.eventsSponsored} </strong>
            </div>
            <div className="w-full border-b flex items-center justify-between pb-5">
              <strong>username: {details.ig_user.username}</strong>
              <strong> Media Count: {details.ig_user.media_count}</strong>
              <strong> Website: <Link to={details.ig_user.website}>{details.ig_user.website}</Link></strong>
            </div>
            <div className="w-full border-b flex items-center justify-between pb-5">
              <strong>Category Name: {x.categoryName ? x.categoryName : "Invalid"} </strong>
              <strong>Number Of Coupons: {x.noOfCoupons ? x.noOfCoupons : "Invalid"}</strong>
              <strong>Number Of Hampers: {x.noOfGiftHampers ? x.noOfGiftHampers : "Invalid"}</strong>
            </div>
          </div>
        ))}
    </>
  );
};

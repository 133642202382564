import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./EventTable.module.css";
import axios from "axios";
import Cookies from "js-cookie";
import { DataContext } from "../../../context/DataContext";
import editIcon from "../../../assets/edit.png";
import delIcon from "../../../assets/del.png";
import EventCard from "./EventCard";


const event = {
  eventName: 'Music Festival 2024',
  startDate: '2024-09-20',
  endtDate: '2024-10-20',
  venue: 'Central Park',
  category: {
    age: '18+',
    gender: 'All',
    followers: '10k+',
  },
  sponsor: {
    sponsorName: 'Brand XYZ',
    coupons: 100,
    giftHampers: 50,
    price: 10000,
  },
  templateData: {
    image: 'https://example.com/image.jpg',
  },
};

const EventTable = ({ eventData, Dashboard }) => {
  const { venues, categories, loading } = useContext(DataContext);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All");
  const eventsPerPage = 4;
  const [dashboard, setDashboard] = useState(Dashboard);
  const navigate = useNavigate();
  const [editingId, setEditingId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    eventName: "",
    categories: "",
    description: "",
    startDate: "",
    endDate: "",
    venue: "",
  });

  useEffect(() => {
    setDashboard(Dashboard);
  }, [Dashboard]);

  if (loading) return <></>;

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;

  const currentEvents = eventData
    ? eventData
    : []
        // console.log("currentEvents", currentEvents)
        .filter((event) =>
          filter === "All" ? true : event.category === filter
        )
        .slice(indexOfFirstEvent, indexOfLastEvent);
  console.log("currentDataEvent", currentEvents);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1);
  };

  const deleteEvent = async (eventId) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      await axios.delete(
        `${apiDomain}/api/v2/deleteevent?id=${eventId}&access_token=${accessToken}`
      );
      alert("Event deleted successfully");
      const updatedEvents = eventData.filter((event) => event.id !== eventId);
      setCurrentPage(
        Math.min(currentPage, Math.ceil(updatedEvents.length / eventsPerPage))
      );
    } catch (error) {
      console.error("Error:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleEditClick = (event) => {
    setEditingId(event.id);
    setEditFormData({
      eventName: event.eventName,
      categories: event.categories,
      description: event.description,
      startDate: event.startDate,
      endDate: event.endDate,
      venue: event.venue,
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleSaveClick = async (id) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      console.log(
        "url",
        `${apiDomain}/api/v2/updateevent?id=${id}&access_token=${accessToken}`
      );
      await axios.put(
        `${apiDomain}/api/v2/updateevent?id=${id}&access_token=${accessToken}`,
        editFormData
      );
      alert("Event updated successfully");
      setEditingId(null);
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to update event");
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
  };

  return (
    <>
      <div className={styles.filterCreateContainer}>
        <div>
          <select
            className={styles.filterButton}
            value={filter}
            onChange={handleFilterChange}
          >
            <option value="All">All</option>
            {eventData &&
              eventData.map((x) => (
                <option key={x.categories} value={x.categories}>
                  {x.categories}
                </option>
              ))}
          </select>
          {!dashboard && (
            <button
              className={styles.createButton}
              onClick={() => navigate("/event-form")}
            >
              + Create
            </button>
          )}
        </div>
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search..."
            className={styles.searchInput}
          />
        </div>
      </div>
    
      {eventData && eventData.map(x => <EventCard event={x} />)}
    </>
  );
};

export default EventTable;

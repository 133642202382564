import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ParticipantTable.module.css";
import { DataContext } from "../../../context/DataContext";
import axios from "axios";
import Cookies from "js-cookie";

const ParticipantTable = () => {
  const { eventData, participant, loading } = useContext(DataContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All");
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [participants, setParticipants] = useState([]);
  const [prevURL, setPrevURL] = useState("");
  const [nextURL, setNextURL] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for Modal visibility
  const [passphrase, setPassphrase] = useState(""); // State for Passphrase input
  const [selectedParticipant, setSelectedParticipant] = useState(null); // State for Selected Participant
  const navigate = useNavigate();

  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const accessToken = Cookies.get("fbauthToken");

  const fetchParticipants = async (eventId, page = 1, pageSize = 10) => {
    try {
      const response = await axios.get(
        `${apiDomain}/api/v2/getparticipantsbyevent`,
        {
          params: {
            event_id: eventId,
            page: page,
            pageSize: pageSize,
            access_token: accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching participants:", error);
      throw error;
    }
  };
  if (loading) <></>;
  console.log("participate page", participant);

  useEffect(() => {
    if (loading || !selectedEvent){
      if(participant)
      setParticipants(participant.participants); 
      return;
    }
      

    const loadParticipants = async () => {
      try {
        Cookies.set("userId", "sidsrbh");
        console.log("selected Event", selectedEvent.eventID);
        const data = await fetchParticipants(
          selectedEvent.eventID,
          currentPage,
          pageSize
        );
        console.log("list participants", data);
        console.log("Cookies", Cookies.get("username"));
        setParticipants(data.participants);
        setPrevURL(data.prev_url);
        setNextURL(data.next_url);
      } catch (error) {
        console.error("Failed to load participants:", error);
      }
    };

    loadParticipants();
  }, [selectedEvent, currentPage, pageSize, loading]);

  const handleFilterChange = (event) => {
    const eventName = event.target.value;
    if(eventName == "All")
    {
      setFilter(eventName);
      setCurrentPage(1);
      console.log("part", participant)
      setParticipants(participant.participants);
      return
    }
    setFilter(eventName);
    setCurrentPage(1); // Reset to first page on filter change
    const selected = eventData.find((e) => e.eventName === eventName);
    setSelectedEvent(selected || null);
  };

  const handlePagination = (direction) => {
    if (direction === "prev" && prevURL) {
      const params = new URLSearchParams(prevURL.split("?")[1]);
      setCurrentPage(parseInt(params.get("page"), 10));
    }
    if (direction === "next" && nextURL) {
      const params = new URLSearchParams(nextURL.split("?")[1]);
      setCurrentPage(parseInt(params.get("page"), 10));
    }
  };

  const handleRowClick = (participant) => {
    setSelectedParticipant(participant);
    setIsModalOpen(true); // Open the modal
  };

  const handlePassphraseSubmit = () => {
    if (passphrase.trim() !== "") {
      console.log(
        "selctted insta",
        selectedParticipant.instagramBusinessAccountId
      );
      navigate(
        `/participants/${selectedParticipant.userId}/${selectedParticipant.instagramBusinessAccountId}?username=${Cookies.get("userId")}&passphrase=${passphrase}`, 
        {
          state: {
            // Add the properties you want to pass in the location.state here
            participant: selectedParticipant, // Replace this with your desired state properties
          }
        }
      );
      

      setIsModalOpen(false); // Close the modal
      setPassphrase(""); // Clear the passphrase input
    }
  };

  return (
    <>
      <div className={styles.filterCreateContainer}>
        <div>
          <select
            className={styles.filterButton}
            value={filter}
            onChange={handleFilterChange}
          >
            {eventData &&
               <option key={"All"} value={"All"}>
               {"All"}
             </option>}
            {eventData &&
              eventData.map((x) => (
                <option key={x.eventName} value={x.eventName}>
                  {x.eventName}
                </option>
              ))}
          </select>
        </div>
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search..."
            className={styles.searchInput}
          />
        </div>
      </div>
      <div>
        <div className={styles.eventsTable}>
          <table className={styles.tableEvent}>
            <thead>
              <tr className={styles.eventTr}>
                <th>Profile Pic.</th>
                <th>Insta User Name</th>
                <th>Followers</th>
                <th>Name</th>
                <th>Date Of Joining</th>
                <th>Events Participated</th>
              </tr>
            </thead>
            <tbody>
              {participants &&
                participants.map((part, index) => (
                  <tr key={index} onClick={() => handleRowClick(part)}>
                    <td>
                      <img
                        src={part.instagramUser.profile_picture_url}
                        alt="/profile-pic"
                        className="w-12 h-12 rounded-full ml-8"
                      />
                    </td>
                    <td>{part.instagramUser.username}</td>
                    <td>{part.instagramUser.followers_count}</td>
                    <td>{part.name}</td>
                    <td>{new Date(part.joiningTime).toLocaleDateString('en-GB')}</td>
                    <td>{part.participationDetails.length}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className={styles.pagination}>
          <button
            onClick={() => handlePagination("prev")}
            disabled={!prevURL}
            className={styles.paginationButton}
          >
            Prev
          </button>
          <p className={styles.pageText}>Page {currentPage}</p>
          <button
            onClick={() => handlePagination("next")}
            disabled={!nextURL}
            className={styles.paginationButton}
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Enter Passphrase</h3>
            <input
              type="text"
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
              placeholder="Passphrase"
              className={styles.modalInput}
            />
            <button
              onClick={handlePassphraseSubmit}
              className={styles.okButton}
            >
              OK
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className={styles.cancelButton}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ParticipantTable;

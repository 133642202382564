import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import backIcon from "./backIcon.png";

const AddCategory = () => {
  const navigate = useNavigate();

  // State for form data
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    startAge: "",
    endAge: "",
    gender: "",
    startCount: "",
    endCount: "",
  });

  const [selectedCategory, setSelectedCategory] = useState("generic");
 

  // List of category options
  const categories = [
    { value: "generic", label: "Generic" },
    { value: "age", label: "Age" },
    { value: "gender", label: "Gender" },
    { value: "followercount", label: "Followers Count" },
  ];

  // Handle change event for category selection
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  // Handle change for input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const accessToken = Cookies.get("fbauthToken");
    const apiDomain = process.env.REACT_APP_API_DOMAIN;

    // Prepare the URL with the selected category type
    const url = `https://${apiDomain}/api/v2/createcategory?categoryType=${selectedCategory}&access_token=${accessToken}`;

    // Prepare the data to be sent based on the selected category
    let dataToSend = {};

    switch (selectedCategory) {
      case "age":
        dataToSend = {
          startAge: parseInt(formData.startAge, 10),
          endAge: parseInt(formData.endAge, 10),
          name: formData.name,
        };
        break;
      case "gender":
        dataToSend = {
          gender: formData.gender,
          name: formData.name,
        };
        break;
      case "followercount":
        dataToSend = {
          startCount: parseInt(formData.startCount, 10),
          endCount: parseInt(formData.endCount, 10),
          name: formData.name,
        };
        break;
      default:
        dataToSend = {
          name: formData.name,
          description: formData.description,
        };
    }
    
    console.log("log",dataToSend)
    // Axios POST request
    axios
      .post(url, dataToSend)
      .then((response) => {
        console.log("Form data saved:", response.data);
        alert("Category added successfully");
        navigate("/"); // Navigate to the home page after successful submission
      })
      .catch((error) => {
        if (error.response) {
          console.log("Error Response:", error.response.data);
          alert(`Error: ${error.response.data}`);
        } else {
          console.log("Error Message:", error.message);
          alert(`Error: ${error.message}`);
        }
      });
  };

  return (
    <div className="flex flex-col items-start p-4 mt-12">
      <form
        className="w-full max-w-3xl p-4 bg-white shadow-lg rounded-lg"
        onSubmit={handleSubmit}
      >
        <div className="flex items-center mb-4">
          <button
            type="button"
            className="mr-4 p-2 bg-white border rounded cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img src={backIcon} alt="backIcon" className="w-4 h-4" />
          </button>
          <h2 className="text-4xl font-semibold text-black">Add Category</h2>
        </div>

        {/* Category Selector */}
        <div className="w-[48%] mb-5">
          <label
            htmlFor="categories"
            className="block text-2xl font-semibold text-black mb-2"
          >
            Category Type
          </label>
          <select
            id="categories"
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            {categories.map((category) => (
              <option key={category.value} value={category.value}>
                {category.label}
              </option>
            ))}
          </select>
        </div>

        {/* Conditional Fields based on Category */}
        {selectedCategory === "generic" && (
          <>
            <div className="w-[48%] mb-5">
              <label
                htmlFor="name"
                className="block text-2xl font-semibold text-black mb-2"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
              />
            </div>
            <div className="w-[48%] mb-5">
              <label
                htmlFor="description"
                className="block text-2xl font-semibold text-black mb-2"
              >
                Description
              </label>
              <input
                type="text"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
              />
            </div>
          </>
        )}

        {selectedCategory === "age" && (
          <>
            <div className="w-[48%] mb-5">
              <label
                htmlFor="startAge"
                className="block text-2xl font-semibold text-black mb-2"
              >
                Start Age
              </label>
              <input
                type="number"
                id="startAge"
                name="startAge"
                value={formData.startAge}
                onChange={handleChange}
                required
                className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
              />
            </div>
            <div className="w-[48%] mb-5">
              <label
                htmlFor="endAge"
                className="block text-2xl font-semibold text-black mb-2"
              >
                End Age
              </label>
              <input
                type="number"
                id="endAge"
                name="endAge"
                value={formData.endAge}
                onChange={handleChange}
                required
                className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
              />
            </div>
            <div className="w-[48%] mb-5">
              <label
                htmlFor="name"
                className="block text-2xl font-semibold text-black mb-2"
              >
                Category Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
              />
            </div>
          </>
        )}

        {selectedCategory === "gender" && (
          <div className="w-[48%] mb-5">
            <label
              htmlFor="gender"
              className="block text-2xl font-semibold text-black mb-2"
            >
              Gender
            </label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
        )}

        {selectedCategory === "followercount" && (
          <>
            <div className="w-[48%] mb-5">
              <label
                htmlFor="startCount"
                className="block text-2xl font-semibold text-black mb-2"
              >
                Start Count
              </label>
              <input
                type="number"
                id="startCount"
                name="startCount"
                value={formData.startCount}
                onChange={handleChange}
                required
                className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
              />
            </div>
            <div className="w-[48%] mb-5">
              <label
                htmlFor="endCount"
                className="block text-2xl font-semibold text-black mb-2"
              >
                End Count
              </label>
              <input
                type="number"
                id="endCount"
                name="endCount"
                value={formData.endCount}
                onChange={handleChange}
                required
                className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
              />
            </div>
          </>
        )}

        <button
          type="submit"
          className="w-full p-4 mt-8 text-xl font-bold text-white bg-blue-500 rounded"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddCategory;

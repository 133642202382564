import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import axios from "axios";
import Cookies from "js-cookie";
import backIcon from "./backIcon.png";
import plusIcon from "./plus.png";
import collapseIcon from "./reduce.png";
import expandIcon from "./expand-arrows.png";
import { DataContext } from "../../context/DataContext";
import Select from "react-select";
import { uploadFile } from "../../../firebase/fileUpload";

const EventType = Object.freeze({
  Short: "short",
  Long: "long",
});
const formatDateForInput = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const day = ("0" + d.getDate()).slice(-2);
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#e5e7eb",
    border: "none",
    boxShadow: "none",
    minHeight: "56px",
    height: "56px",
    borderRadius: "0.375rem",
    padding: "0.5rem",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0.25rem",
    borderRadius: "0.375rem",
    backgroundColor: "#e5e7eb",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0.25rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#b3b3b3" : "#e5e7eb",
    color: "#000",
    cursor: "pointer",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#000",
  }),
};

const EventForm = () => {
  const {
    categories,
    ageCategories,
    genderCategories,
    followerCountCategories,
    venues,
    loading,
  } = useContext(DataContext);

  const location = useLocation(); // Use useLocation to get location state
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({
    eventName: "",
    categories: [],
    description: "",
    startDate: "",
    endDate: "",
    venue: 0,
    votingDuration: 0,
    eventType: "",
    termsConditions: "",
    instructions: "",
    sponsorData: [],
    templateData: null,
  });
  const [templateData, setTemplateData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState("");
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(true);
  const [isCategoriesDetailsOpen, setIsCategoriesDetailsOpen] = useState(true);
  const [isSponsorOpen, setIsSponsorOpen] = useState(true);
  const [isTemplateOpen, setIsTemplateOpen] = useState(true);
  const [sponsorData, setSponsorData] = useState({
    sponsorCategory: "",
    noOfCoupons: "",
    noOfGiftHampers: "",
    description: "",
    price: "",
  });
  const [savedData, setSavedData] = useState([]);

  useEffect(() => {
    // Check if there is an event in location.state to determine edit mode
    if (location.state && location.state.event) {
      setIsEditMode(true);
      setFormData(location.state.event);
      console.log(location.state.event.startDate) // Populate formData with the event data
      setTemplateData(location.state.event.templateData);
      setImagePreview(location.state.event.templateData);
      setSavedData(location.state.event.sponsorData || []);
    }
  }, [location.state]);

  if (loading) <></>;

  const getEnumValues = (enumObject) => Object.values(enumObject);

  const handleEventTypeChange = (event) => {
    const selectedValue = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      eventType: selectedValue,
    }));
    setSelectedEventType(selectedValue);

    const enumKey = Object.keys(EventType).find(
      (key) => EventType[key] === selectedValue
    );
    console.log(`Selected Event Type Enum Key: ${enumKey}`);
  };

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const handleCategoryChange = (selectedOptions, categoryType) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [categoryType]: selectedOptions.map((option) => option.id),
    }));
    console.log("form Data with Category Listing", formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = (name === 'venue' || name === 'votingDuration') ? parseInt(value, 10) || 0 : value;

    setFormData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        [name]: newValue,
      };
      console.log("Updated Form Data:", updatedData);
      return updatedData;
    });
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    const accessToken = Cookies.get("fbauthToken");
    console.log("final form data (create mode)", formData);
    var url = await uploadFile(templateData);
    setImagePreview(url);
    const jsonData = {
      ...formData,
      templateData: url,
    };
    try {
      const response = await axios.post(
        `${apiDomain}/api/v2/createevent?access_token=${accessToken}`,
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log("response", response.statusText);
      if (response.statusText === "OK") {
        alert("Event created successfully!");
        navigate("/questionnaire");
      } else {
        alert(`Error: ${response.data.response.message}`);
      }
    } catch (error) {
      console.error("There was an error creating the event!", error);
      alert("There was an error creating the event. Please try again.");
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
  
    // Ensure you have the API domain and access token
    const accessToken = Cookies.get("fbauthToken");
  
    try {
      // Convert the image file to a base64 string if a new image is uploaded
      let templateData = formData.templateData;
      if (templateData && typeof templateData !== "string") {
        templateData = await toBase64(templateData); // Convert to base64
      }
  
      // Prepare the data to be sent in the request
      const updatedData = {
        eventName: formData.eventName,
        description: formData.description,
        startDate: formData.startDate,
        endDate: formData.endDate,
        venue: formData.venue,
        votingDuration: formData.votingDuration,
        termsConditions: formData.termsConditions,
        eventType: formData.eventType,
        instructions: formData.instructions,
        categories: formData.categories,
        ageCategories: formData.ageCategories,
        genderCategories: formData.genderCategories,
        followerCountCategories: formData.followerCountCategories,
        sponsorData: formData.sponsorData,
        templateData: templateData || "", // If no new image is uploaded, keep the existing one
      };
  
      // Send the PUT request to update the event
      const response = await axios.put(
        `${apiDomain}/api/v2/updateevent?id=${formData.eventID}&access_token=${accessToken}`, // Include the event ID in the query
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      // Handle the response
      if (response.status === 200) {
        alert("Event updated successfully!");
        navigate("/events"); // Redirect to another page if needed
      } else {
        alert(`Error updating event: ${response.data.message}`);
      }
    } catch (error) {
      console.error("There was an error updating the event!", error);
      alert("There was an error updating the event. Please try again.");
    }
  };
  
  // Helper function to convert a file to a base64 string
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Remove the base64 prefix
      reader.onerror = (error) => reject(error);
    });
  };
  

  const handleVenueChange = (event) => {
    const selectedId = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      venue: selectedId,
    }));
  };

  const handleFileChange = async (e) => {
    console.log("entered");
    const file = e.target.files[0];
    if (file) {
      setTemplateData(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSponsorData((prevData) => {
      const updatedSponsorData = {
        ...prevData,
        [name]: value,
      };
      console.log("Updated Sponsor Data:", updatedSponsorData);
      return updatedSponsorData;
    });
  };

  const handleSaveData = () => {
    setSavedData((prevData) => [...prevData, sponsorData]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      sponsorData: [...(prevFormData.sponsorData || []), sponsorData],
    }));
    setSponsorData({
      sponsorCategory: "",
      noOfCoupons: "",
      noOfGiftHampers: "",
      description: "",
      price: "",
    });
    console.log("Updated Saved Data:", savedData);
  };

  const handleDelete = (index) => {
    const newData = savedData.filter((_, i) => i !== index);
    setSavedData(newData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      sponsorData: newData,
    }));
  };

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  return (
    <>
      <div className="flex flex-col items-start w-full mt-12">
        <form
          className="w-[85%] bg-white p-6 rounded-lg shadow-md"
          onSubmit={isEditMode ? handleEditSubmit : handleCreateSubmit} // Check mode to decide which handler to use
        >
          {/* Event Details */}
          <div className="w-full p-4 border rounded-md mb-4">
            <div className="w-full flex items-center justify-between">
              <h2 className="text-3xl font-semibold text-black">
                Event Details
              </h2>
              <button
                type="button"
                onClick={() => setIsEventDetailsOpen(!isEventDetailsOpen)}
                className="flex items-center"
              >
                <img
                  src={isEventDetailsOpen ? collapseIcon : expandIcon}
                  alt={isEventDetailsOpen ? "Collapse" : "Expand"}
                  className="w-6 h-6"
                />
              </button>
            </div>
            {isEventDetailsOpen && (
              <div className="mt-4">
                <div className="flex flex-wrap gap-8 mb-4">
                  <div className="flex-1 min-w-[200px]">
                    <label
                      htmlFor="eventName"
                      className="block text-xl font-semibold text-black mb-2"
                    >
                      Event Name
                    </label>
                    <input
                      type="text"
                      id="eventName"
                      name="eventName"
                      value={formData.eventName}
                      onChange={handleChange}
                      required
                      className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap items-start justify-between">
                  <div className="mb-4 w-[48%]">
                    <label
                      htmlFor="description"
                      className="block text-xl font-semibold text-black mb-2"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                      className="w-full h-32 bg-gray-200 border-none px-4 py-2 rounded resize-y"
                    ></textarea>
                  </div>
                  <div className="mb-4 w-[48%]">
                    <label
                      htmlFor="termsConditions"
                      className="block text-xl font-semibold text-black mb-2"
                    >
                      Terms & Conditions
                    </label>
                    <textarea
                      id="termsConditions"
                      name="termsConditions"
                      value={formData.termsConditions}
                      onChange={handleChange}
                      required
                      className="w-full h-32 bg-gray-200 border-none px-4 py-2 rounded resize-y"
                    ></textarea>
                  </div>
                  <div className="mb-4 w-[48%]">
                    <label
                      htmlFor="instructions"
                      className="block text-xl font-semibold text-black mb-2"
                    >
                      Instructions
                    </label>
                    <textarea
                      id="instructions"
                      name="instructions"
                      value={formData.instructions}
                      onChange={handleChange}
                      required
                      className="w-full h-32 bg-gray-200 border-none px-4 py-2 rounded resize-y"
                    ></textarea>
                  </div>
                </div>
                <div className="flex flex-wrap gap-8 mb-4">
                  <div className="flex-1 min-w-[200px]">
                    <label
                      htmlFor="startDate"
                      className="block text-xl font-semibold text-black mb-2"
                    >
                      Start Date
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      value={formatDateForInput(formData.startDate)}
                      onChange={handleChange}
                      required
                      className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
                    />
                  </div>
                  <div className="flex-1 min-w-[200px]">
                    <label
                      htmlFor="endDate"
                      className="block text-xl font-semibold text-black mb-2"
                    >
                      End Date
                    </label>
                    <input
                      type="date"
                      id="endDate"
                      name="endDate"
                      value={formatDateForInput(formData.endDate)}
                      onChange={handleChange}
                      required
                      className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap gap-8 mb-4">
                  <div className="flex-1 min-w-[200px]">
                    <label
                      htmlFor="votingDuration"
                      className="block text-xl font-semibold text-black mb-2"
                    >
                      Voting Duration (In Days)
                    </label>
                    <input
                      type="number"
                      id="votingDuration"
                      name="votingDuration"
                      value={formData.votingDuration}
                      onChange={handleChange}
                      required
                      className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
                    />
                  </div>
                  <div className="flex-1 min-w-[200px]">
                    <label
                      htmlFor="eventType"
                      className="block text-xl font-semibold text-black mb-2"
                    >
                      Event Type
                    </label>
                    <select
                      id="eventType"
                      name="eventType"
                      value={formData.eventType}
                      onChange={handleEventTypeChange}
                      required
                      className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
                    >
                      <option value="">Select Event Type</option>
                      {getEnumValues(EventType).map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex-1 min-w-[200px]">
                    <label
                      htmlFor="venue"
                      className="block text-xl font-semibold text-black mb-2"
                    >
                      Venue
                    </label>
                    <select
                      id="venue"
                      name="venue"
                      value={formData.venue}
                      onChange={handleVenueChange}
                      required
                      className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
                    >
                      <option value="">Select Venue</option>
                      {venues.map((venue) => (
                        <option key={venue.id} value={venue.id}>
                          {Object.values(venue)
                            .filter((value) => value)
                            .join(", ")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Categories Details */}
          <div className="w-full p-4 border rounded-md">
            <div className="w-full flex items-center justify-between">
              <h2 className="text-3xl font-semibold text-black">
                Categories Details
              </h2>
              <button
                type="button"
                onClick={() =>
                  setIsCategoriesDetailsOpen(!isCategoriesDetailsOpen)
                }
                className="flex items-center"
              >
                <img
                  src={isCategoriesDetailsOpen ? collapseIcon : expandIcon}
                  alt={isCategoriesDetailsOpen ? "Collapse" : "Expand"}
                  className="w-6 h-6"
                />
              </button>
            </div>
            {isCategoriesDetailsOpen && (
              <div className="mt-4">
                <div className="flex flex-wrap justify-between mb-4">
                  <div className="w-[46%] mt-0">
                    <label className="block text-lg font-medium text-black mb-2">
                      Generic Categories
                    </label>
                    <Select
                      options={categories.map((cat) => ({
                        value: cat.name,
                        label: cat.name,
                        id: cat.id,
                      }))}
                      onChange={(selectedOptions) =>
                        handleCategoryChange(selectedOptions, "categories")
                      }
                      isMulti
                      placeholder="Select Generic Categories"
                      styles={customSelectStyles}
                      className="w-full"
                      value={categories
                        .filter((cat) =>
                          formData.categories.includes(cat.id)
                        )
                        .map((cat) => ({
                          value: cat.name,
                          label: cat.name,
                          id: cat.id,
                        }))}
                    />
                  </div>
                  <div className="w-[46%]">
                    <label className="block text-lg font-medium text-black mb-2">
                      Age Categories
                    </label>
                    <Select
                      options={ageCategories.map((cat) => ({
                        value: cat.name,
                        label: cat.name,
                        id: cat.id,
                      }))}
                      onChange={(selectedOptions) =>
                        handleCategoryChange(selectedOptions, "ageCategories")
                      }
                      isMulti
                      placeholder="Select Age Categories"
                      styles={customSelectStyles}
                      className="w-full"
                      value={ageCategories
                        .filter((cat) =>
                          formData.ageCategories?.includes(cat.id)
                        )
                        .map((cat) => ({
                          value: cat.name,
                          label: cat.name,
                          id: cat.id,
                        }))}
                    />
                  </div>
                  <div className="w-[46%] mt-8">
                    <label className="block text-lg font-medium text-black mb-2">
                      Gender Categories
                    </label>
                    <Select
                      options={genderCategories.map((cat) => ({
                        value: cat.gender,
                        label: cat.gender,
                        id: cat.id,
                      }))}
                      onChange={(selectedOptions) =>
                        handleCategoryChange(
                          selectedOptions,
                          "genderCategories"
                        )
                      }
                      isMulti
                      placeholder="Select Gender Categories"
                      styles={customSelectStyles}
                      className="w-full"
                      value={genderCategories
                        .filter((cat) =>
                          formData.genderCategories?.includes(cat.id)
                        )
                        .map((cat) => ({
                          value: cat.gender,
                          label: cat.gender,
                          id: cat.id,
                        }))}
                    />
                  </div>
                  <div className="w-[46%] mt-8">
                    <label className="block text-lg font-medium text-black mb-2">
                      Follower Count Categories
                    </label>
                    <Select
                      options={followerCountCategories.map((cat) => ({
                        value: `${cat.startCount}-${cat.endCount}`,
                        label: `${cat.startCount}-${cat.endCount}`,
                        id: cat.id,
                      }))}
                      onChange={(selectedOptions) =>
                        handleCategoryChange(
                          selectedOptions,
                          "followerCountCategories"
                        )
                      }
                      isMulti
                      placeholder="Select Follower Count"
                      styles={customSelectStyles}
                      className="w-full"
                      value={followerCountCategories
                        .filter((cat) =>
                          formData.followerCountCategories?.includes(cat.id)
                        )
                        .map((cat) => ({
                          value: `${cat.startCount}-${cat.endCount}`,
                          label: `${cat.startCount}-${cat.endCount}`,
                          id: cat.id,
                        }))}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Sponsor Details */}
          <div className="w-full p-4 border rounded-md mt-8">
            <div className="w-full flex items-center justify-between">
              <h2 className="text-3xl font-semibold text-black">
                Sponsor Details
              </h2>
              <button
                type="button"
                onClick={() => setIsSponsorOpen(!isSponsorOpen)}
                className="flex items-center"
              >
                <img
                  src={isSponsorOpen ? collapseIcon : expandIcon}
                  alt={isSponsorOpen ? "Collapse" : "Expand"}
                  className="w-6 h-6"
                />
              </button>
            </div>
            {isSponsorOpen && (
              <div className="mt-4 flex flex-wrap justify-between">
                <div className="w-[46%] flex flex-col items-start space-y-2">
                  <label>Sponsor Category</label>
                  <input
                    type="text"
                    name="sponsorCategory"
                    value={sponsorData.sponsorCategory}
                    onChange={handleInputChange}
                    className="w-full border rounded-sm px-4 py-2"
                    placeholder="Category name"
                  />
                </div>
                <div className="w-[46%] flex flex-col items-start space-y-2">
                  <label>No Of Coupons</label>
                  <input
                    type="number"
                    name="noOfCoupons"
                    value={sponsorData.noOfCoupons}
                    onChange={handleInputChange}
                    className="w-full border rounded-sm px-4 py-2"
                    placeholder="No of coupons"
                  />
                </div>
                <div className="w-[46%] flex flex-col items-start space-y-2 mt-6">
                  <label>No Of Gift Hampers</label>
                  <input
                    type="number"
                    name="noOfGiftHampers"
                    value={sponsorData.noOfGiftHampers}
                    onChange={handleInputChange}
                    className="w-full border rounded-sm px-4 py-2"
                    placeholder="Gift Hampers"
                  />
                </div>
                <div className="w-[46%] flex flex-col items-start space-y-2 mt-6">
                  <label>Description</label>
                  <input
                    type="text"
                    name="description"
                    value={sponsorData.description}
                    onChange={handleInputChange}
                    className="w-full border rounded-sm px-4 py-2"
                    placeholder="Description"
                  />
                </div>
                <div className="w-[46%] flex flex-col items-start space-y-2 mt-6">
                  <label>Price</label>
                  <input
                    type="text"
                    name="price"
                    value={sponsorData.price}
                    onChange={handleInputChange}
                    className="w-full border rounded-sm px-4 py-2"
                    placeholder="Price"
                  />
                </div>
                <div className="w-full flex justify-end mt-6">
                  <button
                    type="button"
                    onClick={handleSaveData}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Save Data
                  </button>
                </div>

                {/* Saved Data */}
                <div className="w-full mt-6">
                  {savedData.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center p-4 bg-gray-100 mb-4 rounded"
                    >
                      <div>
                        <p>
                          <strong>Category:</strong> {item.sponsorCategory}
                        </p>
                        <p>
                          <strong>Coupons:</strong> {item.noOfCoupons}
                        </p>
                        <p>
                          <strong>Gift Hampers:</strong> {item.noOfGiftHampers}
                        </p>
                        <p>
                          <strong>Description:</strong> {item.description}
                        </p>
                        <p>
                          <strong>Price:</strong> {item.price}
                        </p>
                      </div>
                      <button
                        onClick={() => handleDelete(index)}
                        className="text-red-500 font-bold"
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* Template Details */}

          <div className="w-full p-4 border rounded-md mt-8">
            <div className="w-full flex items-center justify-between">
              <h2 className="text-3xl font-semibold text-black">
                Template Details
              </h2>
              <button
                type="button"
                onClick={() => setIsTemplateOpen(!isTemplateOpen)}
                className="flex items-center"
              >
                <img
                  src={isTemplateOpen ? collapseIcon : expandIcon}
                  alt={isTemplateOpen ? "Collapse" : "Expand"}
                  className="w-6 h-6"
                />
              </button>
            </div>

            {isTemplateOpen && (
              <div className="mt-4 flex flex-wrap justify-between">
                <div className="w-[46%] flex flex-col items-start space-y-2">
                  <label>Template</label>
                  <input
                    id="imageinput"
                    type="file"
                    accept="image/*"
                    className="w-full border rounded-sm px-4 py-2"
                    onChange={handleFileChange}
                  />
                  {imagePreview && (
                    <div className="mt-2">
                      <img
                        src={imagePreview}
                        alt="Uploaded Template Preview"
                        className="w-full max-h-40 object-cover border rounded-sm"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <button
            type="submit"
            className="mt-12 flex items-center justify-center gap-2 px-4 py-2 bg-black text-white rounded shadow-md hover:bg-gray-800"
          >
            <img src={plusIcon} alt="plusIcon" />
            {isEditMode ? "Update" : "Save"} {/* Change button text based on mode */}
          </button>
        </form>
      </div>
    </>
  );
};

export default EventForm;





// import React, { useContext, useState, useEffect } from "react";
// import { resolvePath, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Cookies from "js-cookie";
// import backIcon from "./backIcon.png";
// import plusIcon from "./plus.png";
// import collapseIcon from "./reduce.png";
// import expandIcon from "./expand-arrows.png";
// import { DataContext } from "../../context/DataContext";
// import Select from "react-select";
// import { uploadFile } from "../../../firebase/fileUpload";

// const EventType = Object.freeze({
//   Short: "short",
//   Long: "long",
// });

// const customSelectStyles = {
//   control: (provided) => ({
//     ...provided,
//     backgroundColor: "#e5e7eb",
//     border: "none",
//     boxShadow: "none",
//     minHeight: "56px",
//     height: "56px",
//     borderRadius: "0.375rem",
//     padding: "0.5rem",
//   }),
//   menu: (provided) => ({
//     ...provided,
//     marginTop: "0.25rem",
//     borderRadius: "0.375rem",
//     backgroundColor: "#e5e7eb",
//   }),
//   menuList: (provided) => ({
//     ...provided,
//     padding: "0.25rem",
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     backgroundColor: state.isSelected ? "#b3b3b3" : "#e5e7eb",
//     color: "#000",
//     cursor: "pointer",
//   }),
//   placeholder: (provided) => ({
//     ...provided,
//     color: "#000",
//   }),
// };

// const EventForm = () => {
//   const {
//     categories,
//     ageCategories,
//     genderCategories,
//     followerCountCategories,
//     venues,
//     loading,
//   } = useContext(DataContext);
//   if (loading) <></>;
//   const getEnumValues = (enumObject) => Object.values(enumObject);
//   const [selectedEventType, setSelectedEventType] = useState("");
//   const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(true);
//   const [isCategoriesDetailsOpen, setIsCategoriesDetailsOpen] = useState(true);
//   const [isSponsorOpen, setIsSponsorOpen] = useState(true);
//   const [isTemplateOpen, setIsTemplateOpen] = useState(true);
//   const [templateData, setTemplateData] = useState(null);
//   const handleEventTypeChange = (event) => {
//     const selectedValue = event.target.value;
//     setFormData((prevData) => ({
//       ...prevData,
//       eventType: selectedValue,
//     }));
//     setSelectedEventType(selectedValue);

//     const enumKey = Object.keys(EventType).find(
//       (key) => EventType[key] === selectedValue
//     );
//     console.log(`Selected Event Type Enum Key: ${enumKey}`);
//   };
//   const apiDomain = process.env.REACT_APP_API_DOMAIN;
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     eventName: "",
//     categories: [],
//     description: "",
//     startDate: "",
//     endDate: "",
//     venue: 0,
//     votingDuration: 0,
//     eventType: "",
//   });

//   const handleCategoryChange = (selectedOptions, categoryType) => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [categoryType]: selectedOptions.map((option) => option.id),
//     }));
//     console.log("form Data with Category Listing", formData);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
    
//     // Convert the value to an integer if the field is 'venue' or 'votingDuration'
//     const newValue = (name === 'venue' || name === 'votingDuration') ? parseInt(value, 10) || 0 : value;
  
//     setFormData((prevFormData) => {
//       const updatedData = {
//         ...prevFormData,
//         [name]: newValue,
//       };
//       console.log("Updated Form Data:", updatedData); // Log the updated state
//       return updatedData;
//     });
//   };
  

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const accessToken = Cookies.get("fbauthToken");
//     console.log("final form data", formData);
//     var url = await uploadFile(templateData)
//     var prevUrl = url;
//     console.log('template url', prevUrl)
//     setImagePreview(prevUrl);
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       templateData: url,
//     }));
//     console.log("final form data", formData);
//     // Convert file to base64 string
//     const reader = new FileReader();
//     reader.onloadend = () => {
//      // Update the form data with the base64 string
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         templateData: url,
//       }));
//     };
//     const jsonData = {
//       ...formData,
//       templateData: url,// Assuming templateData is a string (e.g., base64) or a suitable JSON representation
//     };
//     try {
//       const response = await axios.post(
//         `${apiDomain}/api/v2/createevent?access_token=${accessToken}`,
//         jsonData, // Send JSON data
//         {
//           headers: {
//             'Content-Type': 'application/json', // Correct content type for JSON
//           },
//         }
//       );
//       console.log("response",response.statusText)
//       if (response.statusText === "OK") {
//         alert("Event created successfully!");
//         navigate("/questionnaire");
//       } else {
//         alert(`Error: ${response.data.response.message}`);
//       }
//     } catch (error) {
//       console.error("There was an error creating the event!", error);
//       alert("There was an error creating the event. Please try again.");
//     }
  
//   };
//   const genericCategories = categories.map((cat) => ({
//     value: cat.name,
//     label: cat.name,
//     id: cat.id,
//   }));

//   const categoryOptions = categories.map((cat) => ({
//     value: cat.name,
//     label: cat.name,
//     id: cat.id,
//   }));

//   const ageCategoryOptions = ageCategories.map((cat) => ({
//     value: cat.name,
//     label: cat.name,
//     id: cat.id,
//   }));

//   const genderCategoryOptions = genderCategories.map((cat) => ({
//     value: cat.gender,
//     label: cat.gender,
//     id: cat.id,
//   }));

//   const followerCountOptions = followerCountCategories.map((cat) => ({
//     value: `${cat.startCount}-${cat.endCount}`,
//     label: `${cat.startCount}-${cat.endCount}`,
//     id: cat.id,
//   }));

//   const [sponsorData, setSponsorData] = useState({
//     sponsorCategory: "",
//     noOfCoupons: "",
//     noOfGiftHampers: "",
//     description: "",
//     price: "",
//   });

//   const [savedData, setSavedData] = useState([]);

//   // Handle input change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setSponsorData((prevData) => {
//       const updatedSponsorData = {
//         ...prevData,
//         [name]: value,
//       };
//       console.log("Updated Sponsor Data:", updatedSponsorData); // Log the updated state
//       return updatedSponsorData;
//     });
//   };

//   // Save input data
//   const handleSaveData = () => {
//     setSavedData((prevData) => [...prevData, sponsorData]);
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       sponsorData: [...(prevFormData.sponsorData || []), sponsorData],
//     }));
//     setSponsorData({
//       sponsorCategory: "",
//       noOfCoupons: "",
//       noOfGiftHampers: "",
//       description: "",
//       price: "",
//     });
//     console.log("Updated Saved Data:", savedData);
//   };

//   // Handle delete data
//   const handleDelete = (index) => {
//     const newData = savedData.filter((_, i) => i !== index);
//     setSavedData(newData);
//   };

//   const handleVenueChange = (event) => {
//     const selectedId = event.target.value;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       venue: selectedId,
//     }));
//   };
//   const [imagePreview, setImagePreview] = useState(null); // State for image preview

//   const handleFileChange = async(e) => {
//     console.log("entered");
//     const file = e.target.files[0];
//     if (file) {
//       setTemplateData(file);
//       setImagePreview(URL.createObjectURL(file)); // Convert file to base64
//     }
//   };
  
//   useEffect(() => {
//     return () => {
//       if (imagePreview) {
//         URL.revokeObjectURL(imagePreview);
//       }
//     };
//   }, [imagePreview]);
//   return (
//     <>
//       <div className="flex flex-col items-start w-full mt-12">
//         <form
//           className="w-[85%] bg-white p-6 rounded-lg shadow-md"
//           onSubmit={handleSubmit}
//         >
//           {/* Event Details */}
//           <div className="w-full p-4 border rounded-md mb-4">
//             <div className="w-full flex items-center justify-between">
//               <h2 className="text-3xl font-semibold text-black">
//                 Event Details
//               </h2>
//               <button
//                 type="button"
//                 onClick={() => setIsEventDetailsOpen(!isEventDetailsOpen)}
//                 className="flex items-center"
//               >
//                 <img
//                   src={isEventDetailsOpen ? collapseIcon : expandIcon}
//                   alt={isEventDetailsOpen ? "Collapse" : "Expand"}
//                   className="w-6 h-6"
//                 />
//               </button>
//             </div>
//             {isEventDetailsOpen && (
//               <div className="mt-4">
//                 <div className="flex flex-wrap gap-8 mb-4">
//                   <div className="flex-1 min-w-[200px]">
//                     <label
//                       htmlFor="eventName"
//                       className="block text-xl font-semibold text-black mb-2"
//                     >
//                       Event Name
//                     </label>
//                     <input
//                       type="text"
//                       id="eventName"
//                       name="eventName"
//                       value={formData.eventName}
//                       onChange={handleChange}
//                       required
//                       className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//                     />
//                   </div>
//                 </div>
//                 <div className="flex flex-wrap items-start justify-between">
//                   <div className="mb-4 w-[48%]">
//                     <label
//                       htmlFor="description"
//                       className="block text-xl font-semibold text-black mb-2"
//                     >
//                       Description
//                     </label>
//                     <textarea
//                       id="description"
//                       name="description"
//                       value={formData.description}
//                       onChange={handleChange}
//                       required
//                       className="w-full h-32 bg-gray-200 border-none px-4 py-2 rounded resize-y"
//                     ></textarea>
//                   </div>
//                   <div className="mb-4 w-[48%]">
//                     <label
//                       htmlFor="termsConditions"
//                       className="block text-xl font-semibold text-black mb-2"
//                     >
//                       Terms & Conditions
//                     </label>
//                     <textarea
//                       id="termsConditions"
//                       name="termsConditions"
//                       value={formData.termsConditions}
//                       onChange={handleChange}
//                       required
//                       className="w-full h-32 bg-gray-200 border-none px-4 py-2 rounded resize-y"
//                     ></textarea>
//                   </div>
//                   <div className="mb-4 w-[48%]">
//                     <label
//                       htmlFor="instructions"
//                       className="block text-xl font-semibold text-black mb-2"
//                     >
//                       Instructions
//                     </label>
//                     <textarea
//                       id="instructions"
//                       name="instructions"
//                       value={formData.instructions}
//                       onChange={handleChange}
//                       required
//                       className="w-full h-32 bg-gray-200 border-none px-4 py-2 rounded resize-y"
//                     ></textarea>
//                   </div>
//                 </div>
//                 <div className="flex flex-wrap gap-8 mb-4">
//                   <div className="flex-1 min-w-[200px]">
//                     <label
//                       htmlFor="startDate"
//                       className="block text-xl font-semibold text-black mb-2"
//                     >
//                       Start Date
//                     </label>
//                     <input
//                       type="date"
//                       id="startDate"
//                       name="startDate"
//                       value={formData.startDate}
//                       onChange={handleChange}
//                       required
//                       className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//                     />
//                   </div>
//                   <div className="flex-1 min-w-[200px]">
//                     <label
//                       htmlFor="endDate"
//                       className="block text-xl font-semibold text-black mb-2"
//                     >
//                       End Date
//                     </label>
//                     <input
//                       type="date"
//                       id="endDate"
//                       name="endDate"
//                       value={formData.endDate}
//                       onChange={handleChange}
//                       required
//                       className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//                     />
//                   </div>
//                 </div>
//                 <div className="flex flex-wrap gap-8 mb-4">
//                   <div className="flex-1 min-w-[200px]">
//                     <label
//                       htmlFor="votingDuration"
//                       className="block text-xl font-semibold text-black mb-2"
//                     >
//                       Voting Duration (In Days)
//                     </label>
//                     <input
//                       type="number"
//                       id="votingDuration"
//                       name="votingDuration"
//                       value={formData.votingDuration}
//                       onChange={handleChange}
//                       required
//                       className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//                     />
//                   </div>
//                   <div className="flex-1 min-w-[200px]">
//                     <label
//                       htmlFor="eventType"
//                       className="block text-xl font-semibold text-black mb-2"
//                     >
//                       Event Type
//                     </label>
//                     <select
//                       id="eventType"
//                       name="eventType"
//                       value={formData.eventType}
//                       onChange={handleEventTypeChange}
//                       required
//                       className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//                     >
//                       <option value="">Select Event Type</option>
//                       {getEnumValues(EventType).map((value) => (
//                         <option key={value} value={value}>
//                           {value}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                   <div className="flex-1 min-w-[200px]">
//                     <label
//                       htmlFor="venue"
//                       className="block text-xl font-semibold text-black mb-2"
//                     >
//                       Venue
//                     </label>
//                     <select
//                       id="venue"
//                       name="venue"
//                       value={formData.venue}
//                       onChange={handleVenueChange}
//                       required
//                       className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//                     >
//                       <option value="">Select Venue</option>
//                       {venues.map((venue) => (
//                         <option key={venue.id} value={venue.id}>
//                           {Object.values(venue)
//                             .filter((value) => value)
//                             .join(", ")}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>

//           {/* Categories Details */}
//           <div className="w-full p-4 border rounded-md">
//             <div className="w-full flex items-center justify-between">
//               <h2 className="text-3xl font-semibold text-black">
//                 Categories Details
//               </h2>
//               <button
//                 type="button"
//                 onClick={() =>
//                   setIsCategoriesDetailsOpen(!isCategoriesDetailsOpen)
//                 }
//                 className="flex items-center"
//               >
//                 <img
//                   src={isCategoriesDetailsOpen ? collapseIcon : expandIcon}
//                   alt={isCategoriesDetailsOpen ? "Collapse" : "Expand"}
//                   className="w-6 h-6"
//                 />
//               </button>
//             </div>
//             {isCategoriesDetailsOpen && (
//               <div className="mt-4">
//                 <div className="flex flex-wrap justify-between mb-4">
//                   <div className="w-[46%] mt-0">
//                     <label className="block text-lg font-medium text-black mb-2">
//                       Generic Categories
//                     </label>
//                     <Select
//                       options={genericCategories}
//                       onChange={(selectedOptions) =>
//                         handleCategoryChange(selectedOptions, "categories")
//                       }
//                       isMulti
//                       placeholder="Select Generic Categories"
//                       styles={customSelectStyles}
//                       className="w-full"
//                     />
//                   </div>
//                   <div className="w-[46%]">
//                     <label className="block text-lg font-medium text-black mb-2">
//                       Age Categories
//                     </label>
//                     <Select
//                       options={ageCategoryOptions}
//                       onChange={(selectedOptions) =>
//                         handleCategoryChange(selectedOptions, "ageCategories")
//                       }
//                       isMulti
//                       placeholder="Select Age Categories"
//                       styles={customSelectStyles}
//                       className="w-full"
//                     />
//                   </div>
//                   <div className="w-[46%] mt-8">
//                     <label className="block text-lg font-medium text-black mb-2">
//                       Gender Categories
//                     </label>
//                     <Select
//                       options={genderCategoryOptions}
//                       onChange={(selectedOptions) =>
//                         handleCategoryChange(
//                           selectedOptions,
//                           "genderCategories"
//                         )
//                       }
//                       isMulti
//                       placeholder="Select Gender Categories"
//                       styles={customSelectStyles}
//                       className="w-full"
//                     />
//                   </div>
//                   <div className="w-[46%] mt-8">
//                     <label className="block text-lg font-medium text-black mb-2">
//                       Follower Count Categories
//                     </label>
//                     <Select
//                       options={followerCountOptions}
//                       onChange={(selectedOptions) =>
//                         handleCategoryChange(
//                           selectedOptions,
//                           "followerCountCategories"
//                         )
//                       }
//                       isMulti
//                       placeholder="Select Follower Count"
//                       styles={customSelectStyles}
//                       className="w-full"
//                     />
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>

//           {/* Sponsor Details */}
//           <div className="w-full p-4 border rounded-md mt-8">
//             <div className="w-full flex items-center justify-between">
//               <h2 className="text-3xl font-semibold text-black">
//                 Sponsor Details
//               </h2>
//               <button
//                 type="button"
//                 onClick={() => setIsSponsorOpen(!isSponsorOpen)}
//                 className="flex items-center"
//               >
//                 <img
//                   src={isSponsorOpen ? collapseIcon : expandIcon}
//                   alt={isSponsorOpen ? "Collapse" : "Expand"}
//                   className="w-6 h-6"
//                 />
//               </button>
//             </div>
//             {isSponsorOpen && (
//               <div className="mt-4 flex flex-wrap justify-between">
//                 <div className="w-[46%] flex flex-col items-start space-y-2">
//                   <label>Sponsor Category</label>
//                   <input
//                     type="text"
//                     name="sponsorCategory"
//                     value={sponsorData.sponsorCategory}
//                     onChange={handleInputChange}
//                     className="w-full border rounded-sm px-4 py-2"
//                     placeholder="Category name"
//                   />
//                 </div>
//                 <div className="w-[46%] flex flex-col items-start space-y-2">
//                   <label>No Of Coupons</label>
//                   <input
//                     type="number"
//                     name="noOfCoupons"
//                     value={sponsorData.noOfCoupons}
//                     onChange={handleInputChange}
//                     className="w-full border rounded-sm px-4 py-2"
//                     placeholder="No of coupons"
//                   />
//                 </div>
//                 <div className="w-[46%] flex flex-col items-start space-y-2 mt-6">
//                   <label>No Of Gift Hampers</label>
//                   <input
//                     type="number"
//                     name="noOfGiftHampers"
//                     value={sponsorData.noOfGiftHampers}
//                     onChange={handleInputChange}
//                     className="w-full border rounded-sm px-4 py-2"
//                     placeholder="Gift Hampers"
//                   />
//                 </div>
//                 <div className="w-[46%] flex flex-col items-start space-y-2 mt-6">
//                   <label>Description</label>
//                   <input
//                     type="text"
//                     name="description"
//                     value={sponsorData.description}
//                     onChange={handleInputChange}
//                     className="w-full border rounded-sm px-4 py-2"
//                     placeholder="Description"
//                   />
//                 </div>
//                 <div className="w-[46%] flex flex-col items-start space-y-2 mt-6">
//                   <label>Price</label>
//                   <input
//                     type="text"
//                     name="price"
//                     value={sponsorData.price}
//                     onChange={handleInputChange}
//                     className="w-full border rounded-sm px-4 py-2"
//                     placeholder="Price"
//                   />
//                 </div>
//                 <div className="w-full flex justify-end mt-6">
//                   <button
//                     type="button"
//                     onClick={handleSaveData}
//                     className="bg-blue-500 text-white px-4 py-2 rounded"
//                   >
//                     Save Data
//                   </button>
//                 </div>

//                 {/* Saved Data */}
//                 <div className="w-full mt-6">
//                   {savedData.map((item, index) => (
//                     <div
//                       key={index}
//                       className="flex justify-between items-center p-4 bg-gray-100 mb-4 rounded"
//                     >
//                       <div>
//                         <p>
//                           <strong>Category:</strong> {item.sponsorCategory}
//                         </p>
//                         <p>
//                           <strong>Coupons:</strong> {item.noOfCoupons}
//                         </p>
//                         <p>
//                           <strong>Gift Hampers:</strong> {item.noOfGiftHampers}
//                         </p>
//                         <p>
//                           <strong>Description:</strong> {item.description}
//                         </p>
//                         <p>
//                           <strong>Price:</strong> {item.price}
//                         </p>
//                       </div>
//                       <button
//                         onClick={() => handleDelete(index)}
//                         className="text-red-500 font-bold"
//                       >
//                         X
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//           {/* template Details */}

//           <div className="w-full p-4 border rounded-md mt-8">
//             <div className="w-full flex items-center justify-between">
//               <h2 className="text-3xl font-semibold text-black">
//                 Template Details
//               </h2>
//               <button
//                 type="button"
//                 onClick={() => setIsTemplateOpen(!isTemplateOpen)}
//                 className="flex items-center"
//               >
//                 <img
//                   src={isTemplateOpen ? collapseIcon : expandIcon}
//                   alt={isTemplateOpen ? "Collapse" : "Expand"}
//                   className="w-6 h-6"
//                 />
//               </button>
//             </div>

//             {isTemplateOpen && (
//       <div className="mt-4 flex flex-wrap justify-between">
//         <div className="w-[46%] flex flex-col items-start space-y-2">
//           <label>Template</label>
//           <input
//           id = "imageinput"
//             type="file"
//             accept="image/*"
//             className="w-full border rounded-sm px-4 py-2"
//             onChange={handleFileChange}
//           />
//           {imagePreview && (
//             <div className="mt-2">
//               <img
//                 src={imagePreview}
//                 alt="Uploaded Template Preview"
//                 className="w-full max-h-40 object-cover border rounded-sm"
//               />
//             </div>
//           )}
//         </div>
//       </div>
//     )}
//           </div>

//           <button
//             type="submit"
//             className="mt-12 flex items-center justify-center gap-2 px-4 py-2 bg-black text-white rounded shadow-md hover:bg-gray-800"
//           >
//             <img src={plusIcon} alt="plusIcon" />
//             Save
//           </button>
//         </form>
//       </div>
//     </>
//   );
// };

// export default EventForm;
